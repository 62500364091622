//
// ------------------------------------------ Base
//
.form-field {
  display: block;
  width: 100%;
  height: rem(50px);
  @extend .py-2;
  @extend .px-4;
  font-family: $font-family-base;
  @extend .text-base;
  font-weight: $font-weight-normal;
  line-height: rem(32px);
  border: 1px solid $input-border;
  border-radius: 0;
  transition: color $transition-time-function, background-color $transition-time-function, border-color $transition-time-function;
  @include focus();
  @extend .style-scroll;
  appearance: none;
  -webkit-appearance: none; /* stylelint-disable-line property-no-vendor-prefix, order/properties-order */
  -moz-appearance: none; /* stylelint-disable-line property-no-vendor-prefix */

  &::-ms-expand {
    background-color: transparent;
    border: 0;
  }

  &.is-invalid {
    border-color: $input-invalid-border;

    & ~ .form-feedback.is-invalid {
      display: block;
    }
  }

  &:focus {
    box-shadow: $focus-shadow;
  }

  // Placeholder
  &::placeholder {
    color: $input-placeolder-color;
    opacity: 1;
  }

  &:disabled,
  &[readonly] {
    color: $input-disabled-color;
    background: $input-disabled-background;
    border-color: $input-disabled-border;
  }

  & ~ .form-feedback.is-invalid{
    display: none;
  }
}
