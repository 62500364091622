@mixin focus() {
  &:focus {
    outline: none;
    @include focusKeyboard();
  }
}

@mixin focusKeyboard() {
  [data-whatintent="keyboard"] & {
    outline: 2px dashed $link-hover-color;
    outline-offset: rem(3px);
  }
}
