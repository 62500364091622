//
// ------------------------------------------ Tag mixin
//
@mixin tagColor($color, $background, $border) {
  color: $color;
  background: $background;
  border-color: $border;

  .tag-icon {
    &::before,
    &::after {
      background-color: $color;
    }
  }
}

@mixin tagSmColor($color, $background, $border) {
  color: $color;
  background: $background;
  border-color: $border;

  .tag-sm-icon {
    &::before,
    &::after {
      background-color: $color;
    }
  }
}

//
// ------------------------------------------ Tag base
//
.tag {
  $self: &;
  display: inline-block;

  &-checkbox {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none;

    &:checked + #{$self}-container {
      @include tagColor($link-color, $white, color($softGrays, "100"));

      #{$self}-icon {
        transform: rotate(45deg);
      }
    }

    &:focus {
      + #{$self}-container {
        @include focusKeyboard();
        box-shadow: $focus-shadow;
      }
    }

    &:not(:disabled) {
      + #{$self}-container {
        cursor: pointer;

        &:hover {
          @include tagColor($link-hover-color, $white, $link-hover-color);
        }
      }
    }

    &:disabled {
      + #{$self}-container {
        @include tagColor($link-disabled-color, $white, color($softGrays, "100"));
      }
    }
  }

  &-container {
    display: block;
    @extend .py-1;
    @extend .px-3;
    @extend .text-small;
    line-height: rem(20px);
    border: 1px solid transparent;
    border-radius: rem(15px);
    transition: $transition-base;
    @include tagColor($link-color, $white, $link-color);
  }

  &-content {
    position: relative;
    display: block;
    padding-right: rem(20px);
  }

  &-icon {
    position: absolute;
    top: rem(50%);
    right: 0;
    display: block;
    width: rem(11px);
    height: rem(11px);
    margin-top: rem(-5px);
    transition: $transition-base;

    &::before {
      position: absolute;
      top: rem(5px);
      left: 0;
      width: rem(11px);
      height: 1px;
      content: "";
      background: $link-color;
    }

    &::after {
      position: absolute;
      top: 0;
      left: rem(5px);
      width: 1px;
      height: rem(11px);
      content: "";
      background: $link-color;
    }
  }
}


//
// ------------------------------------------ Tag base
//
.tag-sm {
  $self: &;
  display: inline-block;
  text-decoration: none;

  &-checkbox {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none;

    &:checked + #{$self}-container {
      @include tagColor($link-color, $white, color($softGrays, "100"));

      #{$self}-content {
        padding-right: rem(15px);
      }

      #{$self}-icon {
        display: block;
      }
    }

    &:focus {
      + #{$self}-container {
        @include focusKeyboard();
        box-shadow: $focus-shadow;
      }
    }

    &:not(:disabled) {
      + #{$self}-container {
        cursor: pointer;

        &:hover {
          @include tagSmColor($link-hover-color, $white, $link-hover-color);
        }
      }
    }

    &:disabled {
      + #{$self}-container {
        @include tagSmColor($link-disabled-color, $white, color($softGrays, "100"));
      }
    }
  }

  &-container {
    display: block;
    @extend .py-1;
    @extend .px-3;
    @extend .text-smallest;
    line-height: rem(15px);
    border: 1px solid transparent;
    border-radius: rem(15px);
    transition: $transition-base;
    @include tagColor($link-color, $white, $link-color);
  }

  &-content {
    position: relative;
    display: block;
    transition: padding .25s ease;
  }

  &-icon {
    position: absolute;
    top: rem(50%);
    right: 0;
    display: none;
    width: rem(9px);
    height: rem(9px);
    margin-top: rem(-4px);
    transition: $transition-base;
    transform: rotate(45deg);

    &::before {
      position: absolute;
      top: rem(4px);
      left: 0;
      width: rem(9px);
      height: 1px;
      content: "";
      background: $link-color;
    }

    &::after {
      position: absolute;
      top: 0;
      left: rem(4px);
      width: 1px;
      height: rem(9px);
      content: "";
      background: $link-color;
    }
  }
}

a.tag-sm:hover { /* stylelint-disable-line selector-no-qualifying-type */
  .tag-sm-container {/* stylelint-disable-line selector-no-qualifying-type */
    @include tagSmColor($link-hover-color, $white, $link-hover-color);
  }
}


//
// ------------------------------------------ Tag category
//
.tag-category {
  display: inline-block;
  @extend .py-2;
  @extend .px-4;
  @extend .text-base;
  color: $white;
  background: $black;
  border-top-right-radius: rem(20px);
}
