// stylelint-disable selector-max-type, selector-max-compound-selectors


//
// ------------------------------------------ Navanr navigation
//
.navbar {
  &-collapse {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1001;
    height: calc(100% - #{$navbar-height});
    margin-top: $navbar-height;
    overflow: auto;
    background: theme-color("primary");
    transition: transform .3s;
    transform: translateX(-101%);

    &.open {
      transform: translateX(0);
    }

    @include media(">=md") {
      left: auto;
      width: rem(270px);
      transform: translateX(101%);
    }

    @include media(">=lg") {
      right: auto;
      left: 0;
      width: rem(200px);
      height: 100%;
      height: calc(100% - #{$navbar-height-lg});
      margin-top: $navbar-height-lg;
      transform: translateX(0);
    }
  }

  &-nav {
    $self: &;
    padding: 0;
    margin: 0;
    list-style: none;

    > li {
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid color($purples, "80");

      > a {
        @extend .text-smallest;
        @extend .py-2;
        @extend .px-4;
        position: relative;
        display: flex;
        flex: 1;
        align-items: center;
        min-height: rem(60px);
        color: $white;
        text-decoration: none;
        transition: background $transition-time-function;

        .navbar-nav-icon {
          @extend .mr-3;
          @extend .my-2;
          display: flex;
          flex: rem(0 0 24px);
          align-items: center;
          justify-content: center;
          width: rem(24px);
          height: rem(24px);
          font-size: rem(11px);
          border: 1px solid $white;
          border-radius: 50%;

          &.no-border {
            border: 0;
          }
        }

        &:not(.disabled):not(:disabled) {
          &:hover,
          &:active,
          &.active {
            color: $white;
            background: color($purples, "80");
          }  // stylelint-disable-line selector-no-qualifying-type
        }
      }

      &.has-submenu { // stylelint-disable-line selector-no-qualifying-type
        > a {
          @extend .icon;
          @extend .icon-chevron-small-down;
          @include spacingCalc($element: padding-right, $spaceValue: rem(10px), $space: 4, $space2: 4);

          &::before {
            position: absolute;
            top: 50%;
            right: rem(15px);
            display: block;
            margin-top: rem(-10px);
            font-size: rem(18px);
            transition: transform $transition-time-function;
          }

          &[aria-expanded="true"] {  // stylelint-disable-line selector-no-qualifying-type
            &::before {
              transform: rotate(180deg);
            }
          }
        }
      }
    }

    @include media(">=lg") {
      .hide-on-xl {
        display: none;
      }
    }
  }

  &-subnav.nav {
    > li {
      position: relative;
      border-top: 1px solid color($purples, "80");

      > a {
        @extend .py-2;
        @extend .px-4;
        @extend .text-small;
        @include spacingCalc($element: padding-left, $spaceValue: rem(24px), $space: 4, $space2: 3);
        display: flex;
        align-items: center;
        min-height: rem(60px);
        color: $white;

        &:not(.disabled):not(:disabled) {
          &:hover,
          &:focus,
          &:active,
          &.active {
            color: $white !important; // stylelint-disable-line declaration-no-important
            background: color($purples, "80");
          }  // stylelint-disable-line selector-no-qualifying-type
        }
      }

      &:not(.no-dotted) {
        > a {
          &::before {
            @extend .ml-4;
            position: absolute;
            top: 50%;
            left: rem(15px);
            display: block;
            width: rem(4px);
            height: rem(4px);
            margin-top: rem(-2px);
            content: "";
            background: $white;
            border-radius: 50%;
          }
        }
      }
    }
  }
}
