//
// ------------------------------------------ Base
//
.pagination.nav {
  display: inline-flex;
  background: $white;
  border: 1px solid map-get($softGrays, "20");

  > li {
    margin-top: -1px;
    margin-bottom: -1px;

    > span {
      display: block;
      min-width: rem(28px);
      min-height: rem(30px);
      @extend .px-1;
      text-align: center;
    }

    > a {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: rem(28px);
      height: rem(30px);
      @extend .px-1;
      color: $link-color;
      text-align: center;
      border: 1px solid transparent;

      .icon {
        display: block;
      }

      &:not(.pagination-link-icon) {
        &:disabled,
        &.disabled {
          color: $link-disabled-color;  // stylelint-disable-line selector-no-qualifying-type
          background: map-get($softGrays, "20");
        }
      }

      &.active { // stylelint-disable-line selector-no-qualifying-type
        border-color: $body-color;
      }

      &:not(:disabled):not(.disabled) {
        &:active {
          color: $link-hover-color;
          background: $white;
          border-color: $link-hover-color;
        }

        &:focus {
          box-shadow: $focus-shadow;
        }
      }
    }
  }
}
