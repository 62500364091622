/* stylelint-disable */

// Style scroll
//
.style-scroll {
  // IE11
  scrollbar-base-color: $white;
  scrollbar-face-color: map-get($softGrays, "40");
  scrollbar-3dlight-color: $white;
  scrollbar-highlight-color: $white;
  scrollbar-track-color: $white;
  scrollbar-arrow-color: map-get($softGrays, "40");
  scrollbar-shadow-color: map-get($softGrays, "40");
  scrollbar-dark-shadow-color: map-get($softGrays, "40");

  // Firefox
  scrollbar-color: map-get($softGrays, "40");
  scrollbar-width: rem(8px);

  // Chrome & Safari
  &::-webkit-scrollbar {
    width: rem(8px);
    background-color: $white;
  }

  &::-webkit-scrollbar-track {
    width: rem(8px);
    background-color: $white;
  }

  &::-webkit-scrollbar-thumb {
    width: rem(8px);
    background-color: map-get($softGrays, "40");
  }
}
