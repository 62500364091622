.file-uploader {
  position: relative;

  &-dnd {
    @extend .px-4;
    @extend .py-6;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: color($grays, "60");
    border: 1px dashed color($softGrays, "80");

    &-icon {
      font-size: rem(50px);
      line-height: 1;
    }

    &-label {
      @extend .py-2;
      display: block;
      text-align: center;
    }
  }
}
