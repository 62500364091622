//
// ------------------------------------------ Base
//
.link {
  color: $link-color;
  text-decoration: none;
  background-color: transparent;
  transition: color $transition-time-function;
  @include focus();

  &:not(.disabled):not(:disabled) {
    &:hover,
    &:active {
      color: $link-hover-color;

      span {
        text-decoration: underline;
      }
    }
  }

  // stylelint-disable-next-line selector-no-qualifying-type
  &.disabled,
  &:disabled {
    color: $link-disabled-color !important; // stylelint-disable-line declaration-no-important
    pointer-events: none;
    cursor: default;
  }
}


//
// ------------------------------------------ Icon
//
.link-icon {
  display: inline-flex;
  align-items: center;

  .icon {
    &::before {
      display: block;
    }

    &:first-child {
      @extend .mr-1;
    }

    &:last-child {
      @extend .ml-1;
    }
  }
}


//
// ------------------------------------------ Color
//
.link-danger {
  color: theme-color("danger");
}

.link-white {
  color: $white;

  &:not(.disabled):not(:disabled) {
    &:hover,
    &:focus,
    &:active {
      color: $white;
    }
  }
}
