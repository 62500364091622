//
// ------------------------------------------ Base
//
.breadcrumb {
  display: inline-flex;
  align-items: center;

  > li {
    display: flex;

    > a {
      display: block;
      color: map-get($grays, "60");
    }

    + li {
      @extend .icon;
      @extend .icon-chevron-small-right;

      &::before {
        display: block;
        @extend .px-1;
        line-height: $line-height-base;
      }
    }
  }
}
