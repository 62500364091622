//
// ------------------------------------------ Base
//
.icon-component {
  @extend .link;
  display: flex;

  &-icon {
    display: flex;
    flex: 0 0 rem(24px);
    align-items: center;
    justify-content: center;
    height: rem(24px);
    font-size: 1.2em;
  }

  &-text {
    @extend .pl-3;
    word-break: break-word;
    word-wrap: break-word;
  }
}

// stylelint-disable-next-line selector-no-qualifying-type
a.icon-component {
  text-decoration: none;

  &:hover,
  &:active {

    // stylelint-disable-next-line selector-no-qualifying-type
    .icon-component-text {
      text-decoration: underline;
    }
  }
}
