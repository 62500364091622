//
// ------------------------------------------ Base
//
.nav {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  list-style: none;

  > li {
    > a {
      display: block;
      color: $link-color;
      text-decoration: none;
      transition: color $transition-time-function, background-color $transition-time-function, border-color $transition-time-function;
      @include focus();

      &:not(.disabled):not(:disabled):not(.active) {

        &:hover,
        &:active {
          color: $link-hover-color;
          text-decoration: none;
        }

        &:focus {
          box-shadow: $focus-shadow;
        }
      }

      &.active {
        // stylelint-disable-line selector-no-qualifying-type
        color: $body-color;
      }

      // stylelint-disable-next-line selector-no-qualifying-type
      &.disabled,
      &:disabled { // stylelint-disable-line selector-no-qualifying-type
        color: $link-disabled-color !important; // stylelint-disable-line declaration-no-important
        pointer-events: none;
      }
    }
  }
}
