//
// ------------------------------------------ Base
//
.dropdown {
  position: relative;
  display: inline-block;

  .icon-chevron-small-down {
    transition: transform $transition-time-function;
  }

  &.open {
    .dropdown-menu {
      display: block;
      animation: dropdownMenuOpen forwards $transition-time-function;
    }

    // stylelint-disable-next-line selector-max-class
    .dropdown-toggle.link .icon-chevron-small-down {
      transform: rotate(180deg);
    }
  }
}


//
// ------------------------------------------ Toggle
//
.dropdown-toggle {
  @extend .link;
}


//
// ------------------------------------------ Position
//
.dropright {
  .dropdown-menu {
    right: 0;
    left: auto;
  }
}


//
// ------------------------------------------ Menu
//
.dropdown-menu {
  $self: &;
  position: absolute;
  top: 100%;
  left: 0;
  display: none;
  width: rem(250px);

  > li {
    margin-top: -1px;

    > a {
      position: relative;
      z-index: 1;
      @extend .py-3;
      @extend .px-2;
      text-decoration: none;
      background: $white;
      border: 1px solid color($softGrays, "40");

      &:hover {
        background: color($softGrays, "40");
      }

      &:focus {
        z-index: 2;
        box-shadow: $focus-shadow;
      }

      .dropdown-menu-link-label {
        display: block;
        @extend .pb-2;
        @extend .text-label;
      }

      &:not(.disabled):not(:disabled) {
        .dropdown-menu-link-label {
          color: $body-color;
        }
      }
    }
  }
}

@keyframes dropdownMenuOpen {
  from {
    opacity: 0;
    transform: translateY(.5rem);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}
