// stylelint-disable at-rule-no-vendor-prefix, declaration-no-important, selector-no-qualifying-type, property-no-vendor-prefix

//
// ------------------------------------------ Reboot
//
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba($black, 0);
}

body {
  width: 100%;
  margin: 0;
  overflow-x: hidden;
  font-family: $font-family-base;
  @extend .text-medium;
  font-weight: $font-weight-normal;
  line-height: $line-height-base;
  color: $body-color;
  text-align: left;
  background-color: $body-bg;
}


//
// ------------------------------------------ Table
//

table {
  border-collapse: collapse;
}

caption {
  caption-side: bottom;
}

th {
  text-align: inherit;
}


//
// ------------------------------------------ Button
//

button {
  border-radius: 0;
}


//
// ------------------------------------------ Figures
//
figure {
  margin: 0 0 1rem;
}


//
// ------------------------------------------ Img
//

img {
  vertical-align: middle;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}
