// Retrieve color Sass maps


@function color($list: $softGray, $key: "0") {
  @return map-get($list, $key);
}

@function theme-color($key: "primary") {
  @return map-get($theme-colors, $key);
}

@function gray($key: "100") {
  @return map-get($grays, $key);
}

// Request a theme color level
@function theme-color-level($color-name: "primary", $level: 0) {
  $color: theme-color($color-name);
  $color-base: if($level > 0, $black, $white);
  $level: abs($level);

  @return mix($color-base, $color, $level * $theme-color-interval);
}

// get static value
@function spacing($key: "0", $size: "lg") {
  $spacing: map-get($spacers, $size);
  @return map-get($spacing, $key);
}


// get static value
@mixin spacingCalc($element: "", $spaceValue: "", $space: "", $space2: "") {
  @each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
      $breakpoint-spacers: map-get($spacers, $breakpoint);
      $value1: map-get($breakpoint-spacers, $space);
      $value2: map-get($breakpoint-spacers, $space2);

      @if $value2 {
        #{$element}: calc(#{$spaceValue} + #{$value1} + #{$value2});
      } @else {
        #{$element}: calc(#{$spaceValue} + #{$value1});
      }
    }
  }
}
