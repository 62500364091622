//
// ------------------------------------------ Font face
//

@font-face {
  font-family: GTWalsheimPro;
  font-style: normal;
  font-weight: 400;
  src: url("./../fonts/GT-Walsheim-Pro/GT-Walsheim-Pro-Regular.eot");
  src: url("./../fonts/GT-Walsheim-Pro/GT-Walsheim-Pro-Regular.eot") format("embedded-opentype"), url("./../fonts/GT-Walsheim-Pro/GT-Walsheim-Pro-Regular.woff") format("woff"), url("./../fonts/GT-Walsheim-Pro/GT-Walsheim-Pro-Regular.ttf") format("truetype"), url("./../fonts/GT-Walsheim-Pro/GT-Walsheim-Pro-Regular.svg#GT-Walsheim-Pro-Regular") format("svg");
}

@font-face {
  font-family: GTWalsheimPro;
  font-style: normal;
  font-weight: 500;
  src: url("./../fonts/GT-Walsheim-Pro/GT-Walsheim-Pro-Medium.eot");
  src: url("./../fonts/GT-Walsheim-Pro/GT-Walsheim-Pro-Medium.eot") format("embedded-opentype"), url("./../fonts/GT-Walsheim-Pro/GT-Walsheim-Pro-Medium.woff") format("woff"), url("./../fonts/GT-Walsheim-Pro/GT-Walsheim-Pro-Medium.ttf") format("truetype"), url("./../fonts/GT-Walsheim-Pro/GT-Walsheim-Pro-Medium.svg#GT-Walsheim-Pro-Medium") format("svg");
}

@font-face {
  font-family: GTWalsheimPro;
  font-style: normal;
  font-weight: 600;
  src: url("./../fonts/GT-Walsheim-Pro/GT-Walsheim-Pro-Bold.eot");
  src: url("./../fonts/GT-Walsheim-Pro/GT-Walsheim-Pro-Bold.eot") format("embedded-opentype"), url("./../fonts/GT-Walsheim-Pro/GT-Walsheim-Pro-Bold.woff") format("woff"), url("./../fonts/GT-Walsheim-Pro/GT-Walsheim-Pro-Bold.ttf") format("truetype"), url("./../fonts/GT-Walsheim-Pro/GT-Walsheim-Pro-Bold.svg#GT-Walsheim-Pro-Bold") format("svg");
}


//
// ------------------------------------------ Headings
//

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5 {
  margin-top: 0;
  @extend .mb-4;
  font-family: $font-family-base;
  font-weight: $font-weight-normal;
  line-height: $line-height-base;

  strong,
  b {
    font-weight: 500;
  }
}

h1,
.h1 {
  font-size: rem(32px);

  @include media(">=md") {
    font-size: rem(32px);
  }

  @include media(">=lg") {
    font-size: rem(48px);
  }
}

h2,
.h2 {
  font-size: rem(28px);

  @include media(">=md") {
    font-size: rem(28px);
  }

  @include media(">=lg") {
    font-size: rem(32px);
  }
}

h3,
.h3 {
  font-size: rem(22px);

  @include media(">=md") {
    font-size: rem(22px);
  }

  @include media(">=lg") {
    font-size: rem(28px);
  }
}

h4,
.h4 {
  font-size: rem(16px);

  @include media(">=md") {
    font-size: rem(16px);
  }

  @include media(">=lg") {
    font-size: rem(22px);
  }
}

h5,
.h5 {
  font-size: rem(14px);

  @include media(">=md") {
    font-size: rem(14px);
  }

  @include media(">=lg") {
    font-size: rem(14px);
  }
}


//
// ------------------------------------------ Text
//

p {
  margin-top: 0;
  margin-bottom: $paragraph-margin-bottom;
}

.text-smallest {
  font-size: rem(12px);

  @include media(">=md") {
    font-size: rem(12px);
  }

  @include media(">=lg") {
    font-size: rem(12px);
  }
}

.text-small {
  font-size: rem(14px);

  @include media(">=md") {
    font-size: rem(14px);
  }

  @include media(">=lg") {
    font-size: rem(14px);
  }
}

.text-medium {
  font-size: rem(16px);

  @include media(">=md") {
    font-size: rem(16px);
  }

  @include media(">=lg") {
    font-size: rem(16px);
  }
}

.text-base {
  font-size: rem(18px);

  @include media(">=md") {
    font-size: rem(18px);
  }

  @include media(">=lg") {
    font-size: rem(18px);
  }
}

.text-large {
  font-size: rem(18px);

  @include media(">=md") {
    font-size: rem(22px);
  }

  @include media(">=lg") {
    font-size: rem(22px);
  }
}


//
// ------------------------------------------ Text label
//

.text-label {
  @extend .text-small;
  letter-spacing: rem(2.5px);
}
