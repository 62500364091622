.tile-article-author {
  @extend .my-6;

  &-label {
    @extend .text-small;
    @extend .mb-3;
    display: block;
  }

  &-block {
    @extend .mb-4;
  }

  &-socials {
    font-size: rem(20px);

    >li {
      +li {
        @extend .pl-4;
      }
    }
  }

  &-specializations {
    &-label {
      @extend .text-small;
      @extend .mb-2;
      display: block;
    }
  }

  &-last-modified {
    &-label {
      @extend .text-small;
      @extend .mb-2;
      display: block;
      color: $text-muted-light;
    }
  }
}

.tile-with-top-left-corner {
  position: relative;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    content: "";
    border-color: transparent $primary transparent transparent;
    border-style: solid;
    border-width: rem(0 25px 25px 0);
    transform: rotate(-90deg);
  }
}
