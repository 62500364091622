//
// ------------------------------------------ Variable
//
$tooltip-background-color:    color($grays, "100");
$tooltip-color:               $white;


//
// ------------------------------------------ Tooltip
//
.tooltip {
  position: absolute;
  max-width: rem(160px);

  .tooltip-inner {
    @extend .px-3;
    @extend .py-2;
    color: $tooltip-color;
    @extend .text-smallest;
    background: $tooltip-background-color;
    border-radius: rem(10px);
  }

  .tooltip-arrow {
    position: absolute;
    left: calc(50% - #{rem(10px)});
    width: 0;
    height: 0;
    margin: 0;
    border-color: $tooltip-background-color;
    border-style: solid;
  }

  &-text-center {
    text-align: center;
  }

  &[x-placement^="bottom"] .tooltip-arrow {
    top: rem(-3px);
    border-width: rem(0 6px 3px 6px);
    border-top-color: transparent;
    border-right-color: transparent;
    border-left-color: transparent;
  }

  &[x-placement^="top"] .tooltip-arrow {
    bottom: rem(-3px);
    border-width: rem(3px 6px 0 6px);
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
  }
}
