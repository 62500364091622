.datagrid-header {
  @extend .mt-4;

  &-title {
    @extend .mb-4;
  }

  @include media(">lg") {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-title {
      margin-bottom: 0;
    }
  }
}

.datagrid-sort {
  @extend .my-4;
}

.datagrid-content {
  @extend .my-4;
}

.datagrid-footer {
  @extend .my-4;
  display: flex;
  justify-content: flex-end;
}
