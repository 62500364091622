.docs-h1-lg {
  font-size: rem(48px);
  line-height: rem(56px);

  @media (min-width: 768px) {
    font-size: rem(80px);
    line-height: rem(80px);
  }
}

.docs-h1 {
  font-size: rem(32px);
  line-height: rem(40px);

  @media (min-width: 768px) {
    font-size: rem(48px);
    line-height: rem(56px);
  }
}

.docs-h2 {
  font-size: rem(32px);
  line-height: rem(40px);

  @media (min-width: 768px) {
    font-size: rem(32px);
    line-height: rem(40px);
  }
}

.docs-h3 {
  font-size: rem(24px);
  line-height: rem(32px);

  @media (min-width: 768px) {
    font-size: rem(24px);
    line-height: rem(32px);
  }
}

.docs-h4 {
  font-size: rem(20px);
  line-height: rem(30px);

  @media (min-width: 768px) {
    font-size: rem(20px);
    line-height: rem(30px);
  }
}


.docs-label {
  font-size: rem(14px);
  text-transform: uppercase;
  letter-spacing: rem(1px);
}

.docs-welcome {
  &-sign {
    z-index: -1;
    width: 100%;
    margin-left: 20px;
    background: url("./../img/welcome-sign.png") 50% 50% no-repeat;
    background-size: cover;
    transition: right .2s linear;
    transform: rotate(90deg);

    &::before {
      display: block;
      width: 100%;
      padding-bottom: 92%;
      content: "";
    }
  }

  @include media(">=md") {
    &-sign {
      position: absolute;
      top: rem(64px);
      right: rem(-100px);
      width: 50%;
    }
  }

  @include media(">=lg") {
    &-sign {
      top: rem(100px);
      right: 0;
      width: rem(600px);
    }
  }
}
