// Variables

//
// Color system
//

$purples: (
  "100": #4f2d7f,
  "80": #725799,
  "60": #9681b2,
  "40": #b9abcc,
  "20": #dcd5e6
);

$softGrays: (
  "100": #cbc4bc,
  "80": #d6d0ca,
  "60": #dfdbd6,
  "40": #eae7e4,
  "20": #f5f3f2
);

$oranges: (
  "100": #f26b23,
  "80": #f5894f,
  "60": #f7a67b,
  "40": #fac4a7,
  "20": #fce1d3
);

$greens: (
  "100": #9bd732,
  "80": #afdf5b,
  "60": #c3e784,
  "40": #d7efad,
  "20": #ebf7d6,
);

$teals: (
  "100": #00838f,
  "80": #339ca6,
  "60": #66b5bc,
  "40": #99cdd2,
  "20": #cce6e8
);

$reds: (
  "100": #de002e,
  "80": #e43358,
  "60": #eb6681,
  "40": #f299ab,
  "20": #f9ccd6
);

$grays: (
  "100": #000,
  "80": #333,
  "60": #666,
  "40": #999,
  "20": #ccc
);

$white:    #fff;
$black:    #000;
$purple:   map-get($purples, "100");
$softGray: map-get($softGrays, "100");
$orange:   map-get($oranges, "100");
$green:    map-get($greens, "100");
$teal:     map-get($teals, "100");
$red:      map-get($reds, "100");

$primary:       $purple;
$success:       $green;
$info:          $teal;
$warning:       $orange;
$danger:        $red;

$theme-colors: (
  "primary":    $primary,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger
);


// Body
//

$body-bg:                   $white;
$body-color:                gray("100");

// Paragraphs
//

$paragraph-margin-bottom:   rem(20px);

// Grid breakpoints
//

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1200px,
  xl: 1900px
);

$breakpoints: $grid-breakpoints;


// Grid containers
//

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 1100px,
  xl: 1440px
);


// Grid columns
//

$grid-columns:                12;
$grid-gutter-width:           20px;


// Spacing
//

$spacers: (
  xs: (
    0: 0,
    1: rem(4px),
    2: rem(8px),
    3: rem(12px),
    4: rem(16px),
    5: rem(24px),
    6: rem(30px),
    7: rem(48px),
    8: rem(64px),
  ),
  sm: (
    0: 0,
    1: rem(4px),
    2: rem(8px),
    3: rem(12px),
    4: rem(16px),
    5: rem(24px),
    6: rem(30px),
    7: rem(48px),
    8: rem(64px),
  ),
  md: (
    0: 0,
    1: rem(4px),
    2: rem(8px),
    3: rem(12px),
    4: rem(20px),
    5: rem(24px),
    6: rem(30px),
    7: rem(48px),
    8: rem(64px),
  ),
  lg: (
    0: 0,
    1: rem(4px),
    2: rem(8px),
    3: rem(12px),
    4: rem(20px),
    5: rem(30px),
    6: rem(48px),
    7: rem(64px),
    8: rem(96px),
  ),
  xl: (
    0: 0,
    1: rem(4px),
    2: rem(8px),
    3: rem(12px),
    4: rem(24px),
    5: rem(48px),
    6: rem(64px),
    7: rem(96px),
    8: rem(128px),
  )
);


// stylelint-disable-next-line value-keyword-case
$font-family-base:            GTWalsheimPro, Arial, sans-serif;

$font-weight-normal:          400;
$font-weight-bold:            600;

$line-height-base:            1.5;

$text-muted:                  gray("80");
$text-muted-light:            gray("60");


// Transition
//
$transition-base:             all .25s ease;
$transition-fade:             opacity .15s linear;
$transition-collapse:         height .35s ease;
$transition-time-function:    .2s ease;


// Links
//
$link-color:                theme-color("primary");
$link-hover-color:          theme-color("danger");
$link-disabled-color:       gray("40");

$focus-shadow:              rem(0 0 0 2px) $white, rem(0 0 0 4px) color($purples, "40");


$tile-hover-shadow:         rem(0 0 40px 0) rgba(color($grays, "100"), .15);


$navbar-height:           rem(56px);
$navbar-height-lg:        rem(70px);
$navbar-brand-width:      rem(152px);
$navbar-brand-lg-width:   rem(200px);

// Listbox
//
$listbox-dot-color: color($oranges, "80");
$listbox-dot-size: rem(5px);

// Listbox - pricing
//
$listbox-pricing-color-1: color($softGrays, "100");
$listbox-pricing-color-2: color($oranges, "80");
$listbox-pricing-color-3: $primary;

// Box color
//
$box-softgray-color: color($softGrays, "20");
$box-gray-color: color($softGrays, "40");

// Video box
//
$video-color:       $white;
$video-color-hover: $red;
$video-background: (
  "xs": linear-gradient(180deg, transparent 0%, rgba(0, 0, 0, .5) 100%, rgba(0, 0, 0, .65) 0, rgba(0, 0, 0, .65)),
  "xl": linear-gradient(180deg, transparent 20%, rgba(0, 0, 0, .5) 100%, rgba(0, 0, 0, .65) 0, rgba(0, 0, 0, .65))
);

$video-size: (
  "xs": rem(60px),
  "xl": rem(50px)
);
$video-pos-right:   rem(25px);
$video-pos-bottom:  rem(30px);
