//
// ------------------------------------------ Base
//
.user-card-small {
  display: flex;

  &-img {
    flex: rem(0 0 40px);
    width: rem(40px);
    height: rem(40px);
    background: color($softGrays, "40");
    border-radius: 50%;
  }

  &-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    @extend .pl-3;
    width: 100%;
  }

  &-name {
    display: block;
    line-height: 1.3;
  }

  &-position {
    display: block;
    line-height: 1.3;
  }
}

a.user-card-small { /* stylelint-disable-line selector-no-qualifying-type */
  text-decoration: none;
}

a.user-card-small:hover .user-card-small-name { /* stylelint-disable-line selector-no-qualifying-type */
  text-decoration: underline;
}
