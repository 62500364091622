.listbox {
  $this: &;
  position: relative;

  @include media-breakpoint-up(sm) {
    display: flex;
    align-items: flex-start;
    justify-items: flex-start;
  }


  &-item {
    position: relative;
    margin-bottom: rem(15px);
    text-align: center;

    &:last-child {
      margin-bottom: 0;
    }

    @include media-breakpoint-up(sm) {
      flex: 1;
      margin: 0;
    }
  }

  &-with-dots {
    #{$this}-item {
      &::before {
        position: absolute;
        top: 8px;
        left: 0;
        width: $listbox-dot-size;
        height: $listbox-dot-size;
        content: "";
        background: $listbox-dot-color;
        border-radius: 50%;
      }
    }
  }

  &-color {
    #{$this}-item {
      padding: rem(0 45px);

      &::after {
        position: absolute;
        top: 0;
        left: 0;
        width: rem(3px);
        height: 100%;
        content: "";
        background: color($oranges, "60");
      }

      &::before {
        left: rem(30px);
      }
    }
  }

  &-pricing {

    #{$this}-item {
      flex-basis: 0;
      flex-grow: 1;
      padding: rem(0 20px);
      margin-right: rem(10px);
      background: $white;

      &:last-child {
        margin-right: 0;
      }

      &:nth-child(n) {
        border: 1px solid $listbox-pricing-color-1;
        border-top-width: 3px;

        #{$this}-pricing-price,
        #{$this}-pricing-color {
          color: $listbox-pricing-color-1;
        }
      }

      &:nth-child(2n) {
        border: 1px solid $listbox-pricing-color-2;
        border-top-width: 3px;

        #{$this}-pricing-price,
        #{$this}-pricing-color {
          color: $listbox-pricing-color-2;
        }
      }

      &:nth-child(3n) {
        border: 1px solid $listbox-pricing-color-3;
        border-top-width: 3px;

        #{$this}-pricing-price,
        #{$this}-pricing-color {
          color: $listbox-pricing-color-3;
        }
      }
    }

    &-price,
    &-caption,
    &-caption-2 {
      @extend .text-center;
    }

    &-price {
      font-size: rem(32px);
      font-weight: 600;

      sup {
        @extend .text-small;

        position: relative;
        top: rem(-18px);
        color: $text-muted;
      }
    }

    &-caption,
    &-caption-2 {
      @extend .text-smallest;
      color: $softGray;
    }

    &-caption {
      margin-bottom: rem(20px);
    }

    &-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: rem(150px);
    }

    &-info {
      padding: rem(15px 0);
      border-top: 1px solid $softGray;
    }

    &-legend {
      @extend .text-small;

      margin-bottom: rem(20px);
      line-height: 1.8;
      color: $softGray;
    }

    &-desc {
      @extend .text-small;

      padding-top: rem(20px);
      border-top: 1px solid $softGray;

      span {
        color: color($oranges, "80");
      }
    }
  }
}
