//
// ------------------------------------------ Base
//
.badge {
  @extend .text-small;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: rem(24px);
  min-height: rem(24px);
  padding: rem(2px 5px);
  font-weight: 600;
  line-height: rem(20px);
  text-align: center;
  vertical-align: middle;
  border-radius: rem(20px);

  > .icon::before {
    display: block;
  }

  &-success {
    color: $white;
    background: theme-color("info");
  }

  &-error {
    color: $white;
    background: theme-color("danger");
  }
}
