// stylelint-disable selector-max-type, selector-max-compound-selectors


//
// ------------------------------------------ Top bar
//
.navbar-top-bar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1101;
  width: 100%;
  background: $white;
  border-bottom: 1px solid color($softGrays, "40");

  &-container {
    display: flex;
    align-items: center;
    height: $navbar-height;
  }

  &-content {
    @extend .py-3;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-end;
    height: $navbar-height;
  }

  &-aside {
    display: none;

    @include media(">=lg") {
      display: block;
    }
  }

  &-main {
    @extend .mr-n4;
  }

  &-col {
    @extend .px-4;

    &-hidden-xs {
      display: none;
    }
  }

  @include media(">=lg") {
    &-container {
      height: $navbar-height-lg;
    }

    &-main {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    &-col {
      position: relative;
      flex: auto;

      &::before {
        position: absolute;
        top: 50%;
        right: 0;
        display: block;
        width: 1px;
        height: rem(24px);
        margin-top: rem(-12px);
        content: "";
        background: color($softGrays, "40");
      }

      &-hidden-lg {
        display: none;
      }

      &-hidden-xs {
        display: block;
      }

      &:last-child {
        &::before {
          display: none;
        }
      }
    }
  }
}


//
// ------------------------------------------ Brand
//
.navbar-top-bar-brand {
  flex: rem(0 0 150px);
  width: rem(150px);
  margin-bottom: -1px;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    height: $navbar-height;
  }

  &-img {
    width: 100%;
  }

  .navbar-top-bar-logged-in & {
    flex: 0 0 $navbar-brand-width;
    width: $navbar-brand-width;
    margin-left: -20px;
    background: #4e2e81;
    border-bottom: 1px solid color($purples, "80");

    &-img {
      width: rem(126px);
    }
  }

  @include media(">=lg") {
    flex: rem(0 0 215px);
    width: rem(215px);

    a {
      height: $navbar-height-lg;
    }

    .navbar-top-bar-logged-in & {
      flex: 0 0 $navbar-brand-lg-width;
      width: $navbar-brand-lg-width;
    }
  }
}


//
// ------------------------------------------ Toggle button
//
.navbar-top-bar-toggle-btn {
  display: flex;
  float: right;

  &-label {
    @extend .text-small;
    @extend .pr-3;
    display: block;
    line-height: rem(17px);
    color: #4e2e81;
    text-transform: uppercase;
    letter-spacing: rem(2.5px);
  }

  &-icon {
    position: relative;
    display: block;
    float: right;
    width: rem(17px);
    height: rem(17px);
    padding-top: rem(2px);

    .bar-icon {
      display: block;
      width: rem(17px);
      height: rem(1px);
      background: #4e2e81;
      transition: all $transition-time-function;
      transform: 0;

      + .bar-icon {
        margin-top: rem(4px);
      }

      &:first-child {
        transform-origin: top left;
      }

      &:last-child {
        transform-origin: bottom left;
      }
    }
  }

  &[aria-expanded="true"] {
    .bar-icon {
      &:first-child {
        transform: rotate(45deg);
      }

      &:nth-child(2) {
        opacity: 0;
      }

      &:last-child {
        margin-top: rem(6px);
        transform: rotate(-45deg);
      }
    }
  }

  @include media(">=lg") {
    display: none;
  }
}


//
// ------------------------------------------ Icon link
//
.navbar-top-bar-link-icon {
  display: block;
  font-size: rem(24px);
  color: $link-color;
  text-decoration: none;

  &:not(.disabled):not(:disabled) {
    &:hover,
    &:active {
      color: $link-hover-color;
    }
  }
}


//
// ------------------------------------------ Language nav
//
.navbar-top-bar-nav-lang {
  > li {
    position: relative;

    + li {
      @extend .pl-4;

      &::before {
        position: absolute;
        top: rem(3px);
        bottom: rem(3px);
        left: rem(8px);
        width: 1px;
        content: "";
        background: $body-color;
      }
    }

    > a {
      text-transform: uppercase;

      &.active {  // stylelint-disable-line selector-no-qualifying-type
        font-weight: 600;
      }
    }
  }
}


//
// ------------------------------------------ User dropdown
//
.navbar-top-bar-user-dropdown {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-decoration: none;

  &-content {
    flex: auto;
    max-width: rem(300px);
  }

  &-name {
    display: block;
    width: 100%;
    overflow: hidden;
    line-height: 1.2;
    color: $body-color;
    text-align: right;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &-position {
    display: block;
    width: 100%;
    overflow: hidden;
    line-height: 1.2;
    color: $text-muted-light;
    text-align: right;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &-img {
    @extend .ml-3;
    display: block;
    flex: rem(0 0 40px);
    width: rem(40px);
    height: rem(40px);
    background-color: color($softGrays, "60");
    border-radius: 50%;
  }

  &-arrow {
    display: block;
    @extend .ml-3;

    .icon::before {
      transition: transform $transition-time-function;

      .dropdown.open & {
        transform: rotate(180deg);
      }
    }
  }
}
