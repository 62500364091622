.skip-to-content {
  position: absolute !important; // stylelint-disable-line declaration-no-important
  top: rem(10px);
  left: (10px);
  z-index: 1102;

  &-text {
    display: block;
    @extend .px-6;
    @extend .py-4;
    color: theme-color("danger");
    text-decoration: none;
    white-space: nowrap;
    background: $white;
    border-color: theme-color("danger");
    transition: color $transition-time-function, background-color $transition-time-function, border-color $transition-time-function;
  }
}
