//
// ------------------------------------------ Base
//
.select {
  position: relative;

  .select-toggle-icon {
    transition: transform $transition-time-function;
  }

  &.open {
    .select-menu {
      display: block;
    }

    .select-toggle-icon {
      transform: rotate(180deg);
    }
  }
}


//
// ------------------------------------------ Select toggle
//

.select-toggle {
  $self: &;
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  color: $link-color;
  text-align: left;
  text-decoration: none;
  white-space: nowrap;
  user-select: none;
  background: transparent;
  border: 0;
  border-radius: 0;
  transition: color $transition-time-function, background-color $transition-time-function, border-color $transition-time-function;
  transition: $transition-base;
  @include focus();

  &::after {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    height: 1px;
    content: "";
    background: $link-color;
  }

  &-text {
    display: block;
    width: calc(100% - #{rem(30px)});
    margin-right: rem(30px);
    @extend .py-2;
    font-family: $font-family-base;
    font-weight: $font-weight-normal;
    line-height: 2.125;
    @include text-truncate;
  }

  &-icon {
    position: absolute;
    top: 50%;
    right: rem(10px);
    margin-top: rem(-8px);

    .icon::before {
      display: block;
    }
  }

  &.is-invalid {
    #{$self}-text {
      color: $label-invalid-color;
    }

    &::after {
      background: $input-invalid-border;
    }
  }

  &.is-valid {
    #{$self}-text {
      color: $label-valid-color;
    }

    &::after {
      background: $input-valid-border;
    }
  }

  &:disabled {
    color: $link-disabled-color;
  }

  &:not(:disabled) {
    cursor: pointer;

    &:hover {
      color: $link-hover-color;
    }

    &:focus {
      box-shadow: $focus-shadow;
    }
  }
}


//
// ------------------------------------------ Menu
//
.select-menu {
  $self: &;
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  z-index: 10;
  display: none;

  > li {
    position: relative;
    @extend .py-3;
    @extend .px-2;
    padding-right: rem(30px);
    color: color($grays, "100");
    background: $white;
    transition: $transition-base;

    &[aria-disabled="true"] { // stylelint-disable-line selector-no-qualifying-type
      color: color($grays, "60");
      background: color($softGrays, "20");

      #{$self}-with-img-picture {
        opacity: .7;
      }
    }

    &[aria-selected="true"] { // stylelint-disable-line selector-no-qualifying-type
      &::after {
        position: absolute;
        top: rem(50%);
        right: rem(10px);
        margin-top: rem(-8px);
        font-family: amethyst; // stylelint-disable-line font-family-no-missing-generic-family-keyword
        font-size: rem(12px);
        content: "\e114";
      }
    }

    &:not([aria-disabled="true"]) {
      cursor: pointer;

      &:hover {
        color: $link-hover-color;
        text-decoration: underline;
        background: $white;
      }

      &:focus {
        outline: none;
        box-shadow: $focus-shadow;
      }
    }
  }

  &-with-img {
    > li {
      display: flex;
    }

    &-picture {
      display: block;
      flex: rem(0 0 24px);
      align-self: flex-start;
      @extend .mr-3;
      max-width: rem(24px);
      height: auto;
      border-radius: 50%;
    }
  }
}
