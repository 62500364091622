.img {
  &-wrapper {
    display: flex;
    flex-direction: column;
  }

  &-fluid {
    display: block;
    max-width: 100%;
    height: auto;
  }

  &-full {
    display: block;
    width: 100%;
    height: auto;
  }

  &-center {
    margin-right: auto;
    margin-left: auto;
  }

  &-circle {
    border-radius: 50%;
  }
}
