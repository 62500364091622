//
// ------------------------------------------ Variable
//
$btn-line-height:             rem(30px);
$btn-border-width:            2px;
$btn-disabled:                color($softGrays, "100");


//
// ------------------------------------------ Base
//
.btn {
  display: inline-block;
  @extend .py-2;
  @extend .px-4;
  min-width: rem(80px);
  font-family: $font-family-base;
  @extend .text-base;
  font-weight: $font-weight-normal;
  line-height: $btn-line-height;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: $btn-border-width solid transparent;
  border-radius: 0;
  transition: color $transition-time-function, background-color $transition-time-function, border-color $transition-time-function;
  @include focus();

  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
  }

  &:not(:disabled):not(.disabled) {
    cursor: pointer;

    &:focus {
      box-shadow: $focus-shadow;
    }
  }

  &.disabled,
  &:disabled {
    pointer-events: none;
  }
}


//
// ------------------------------------------ Color
//
// stylelint-disable-next-line no-duplicate-selectors
.btn {
  &-primary {
    color: $white;
    background: theme-color("primary");
    border-color: theme-color("primary");

    &:not(:disabled):not(.disabled) {
      &:hover {
        color: theme-color("danger");
        background: $white;
        border-color: theme-color("danger");
      }

      &:active {
        color: $white;
        background: theme-color("danger");
        border-color: theme-color("danger");
      }
    }

    &.disabled,
    &:disabled {
      color: $white;
      background: $btn-disabled;
      border-color: $btn-disabled;
    }
  }

  &-secondary {
    color: theme-color("primary");
    background: $white;
    border-color: theme-color("primary");

    &:not(:disabled):not(.disabled) {
      &:hover {
        color: $white;
        background: theme-color("danger");
        border-color: theme-color("danger");
      }

      &:active {
        color: theme-color("danger");
        background: $white;
        border-color: theme-color("danger");
      }
    }

    &.disabled,
    &:disabled {
      color: $btn-disabled;
      background: $white;
      border-color: $btn-disabled;
    }
  }

  &-tertiary {
    color: theme-color("primary");
    background: transparent;
    border-color: transparent;

    &:not(:disabled):not(.disabled) {
      &:hover {
        color: theme-color("danger");
        background: transparent;
        border-color: transparent;
      }

      &:active {
        color: theme-color("danger");
        background: transparent;
        border-color: transparent;
      }
    }

    &.disabled,
    &:disabled {
      color: $btn-disabled;
      background: transparent;
      border-color: transparent;
    }
  }
}


//
// ------------------------------------------ Icon
//
.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: rem(50px);
  height: rem(50px);
  padding: 0;
  line-height: rem(50px);
  @extend .text-large;

  .icon {
    display: block;

    &::before {
      display: block;
    }
  }
}


//
// ------------------------------------------ Icon
//
.btn-block-xs {
  @include media("<lg") {
    display: block;
    width: 100%;
  }
}
