
.login-page-bkg {
  position: fixed;
  top: $navbar-height;
  right: 0;
  bottom: 0;
  left: 0;
  background-position: 50%;
  background-size: cover;

  &::before {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: 100%;
    height: 100%;
    content: "";
    background: #000;
    opacity: .6;
  }

  @include media(">=lg") {
    top: $navbar-height-lg;

    &::before {
      background-color: transparent;
      background-image: linear-gradient(to bottom, transparent, #000 200px);
    }
  }
}

.login-page-article {
  @extend .my-6;
  color: $white;
}

.login-page-form {
  @extend .my-6;
  @extend .py-5;
  @extend .px-4;
  background: $white;

  &-register-link {
    @extend .mt-4;
    margin-bottom: 0;
    text-align: center;
  }
}

.login-page-content {
  @media only screen and (min-width: 1400px) {
    &-pt {
      padding-top: 10vh;
    }
  }
}
