//
// ------------------------------------------ Base
//
.status {
  $self: &;
  display: inline-flex;
  align-items: center;

  &-icon {
    @extend .mr-2;
    display: flex;
    align-items: center;
    justify-content: center;
    width: rem(24px);
    height: rem(24px);
    font-size: rem(12px);
    border-radius: 50%;

    .icon::before {
      display: block;
    }
  }

  &-text {
    display: block;
    @extend .text-medium;
  }

  &-positive {
    #{$self}-icon {
      background: color($greens, "20");
    }
  }

  &-neutral {
    #{$self}-icon {
      background: color($teals, "20");
    }
  }

  &-warning {
    #{$self}-icon {
      background: color($oranges, "20");
    }
  }

  &-alert {
    #{$self}-icon {
      background: color($reds, "20");
    }
  }

  &-archival {
    #{$self}-icon {
      background: color($grays, "20");
    }
  }
}
