.modal-open {
  overflow: hidden;
}

.modal {
  position: fixed;
  top: -100%;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1000;
  display: block;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  opacity: 0;
  transition: all $transition-time-function;
  transform: translate(0, -25%);

  &.show {
    top: 0;
    z-index: 1102;
    opacity: 1;
    transform: translate(0, 0);
  }
}


.modal-dialog {
  @extend .p-3;
  position: relative;
  width: auto;
  pointer-events: none;

  @include media(">=md") {
    display: flex;
    align-items: center;
    max-width: rem(780px);
    min-height: calc(100% - (#{rem(30px)} * 2));
    margin: rem(30px);
    margin-right: auto;
    margin-left: auto;

    &::before {
      display: block;
      height: calc(100vh - (#{rem(30px)} * 2) - #{rem(60px)});
      content: "";
    }
  }
}


.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: $white;
  background-clip: padding-box;
  outline: 0;

  @include media(">=md") {
    min-height: auto;
  }
}


.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  opacity: 0;
  transition: opacity $transition-time-function;

  &.show {
    z-index: 1101;
    background-color: color($grays, "100");
    opacity: .4;
  }
}


.modal-header {
  @extend .p-4;
  position: relative;

  &-title {
    margin: rem(0 40px 0 0);
  }

  &-close {
    position: absolute;
    top: rem(15px);
    right: rem(15px);
    display: block;
    padding: rem(5px);
    font-size: rem(20px);
    cursor: pointer;
    background: none;
    border: 0;

    .icon::before {
      display: block;
    }
  }
}


.modal-body {
  @extend .px-4;
  @extend .pb-4;
}


.modal-footer {
  @extend .px-4;
  @extend .pb-4;

  &-nav {
    display: flex;
    align-items: center;

    >li {
      @extend .pt-3;
      flex: 0 0 100%;

      +li {
        @extend .pt-3;
      }

      >.btn {
        display: block;
        width: 100%;
      }
    }
  }

  @include media(">sm") {
    &-nav {
      flex-direction: row-reverse;

      >li {
        flex: 0 0 auto;
        padding-top: 0 !important; /* stylelint-disable-line declaration-no-important */
      }
    }
  }
}
