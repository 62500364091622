.testimonial {
  $this: &;
  position: relative;

  @include media-breakpoint-up(sm) {
    display: flex;
    align-items: flex-start;
    justify-items: flex-start;
  }

  &-item {
    position: relative;
    padding-top: rem(10px);
    margin-bottom: rem(15px);

    &:last-child {
      margin-bottom: 0;
    }

    @include media-breakpoint-up(sm) {
      flex-basis: 0;
      flex-grow: 1;
      padding: rem(10px 0 0 40px);
      // padding-right: rem(30px);
      margin: 0;
    }

    .icon {
      position: absolute;
      top: 0;
      left: 0;
      font-size: rem(32px);
      color: $softGray;
    }
  }

  &-name {
    font-size: rem(18px);
  }

  &-company {
    font-size: rem(14px);
    line-height: 2;
    color: $softGray;
  }

  &-content {
    @extend .custom-bullet;
    margin-top: rem(10px);
    font-size: rem(14px);

    ul {
      padding: 0;
    }

    li {
      padding: rem(5px 0 5px 10px);

      &::before {
        top: rem(13px);
      }
    }
  }
}
