//
// ------------------------------------------ Base
//
.article-header {
  position: relative;
  overflow: hidden;

  &-content {
    @extend .py-5;
  }

  &-sign {
    display: none;
  }

  &-attributes {
    display: flex;
    align-items: center;
    @extend .mx-n1;
    @extend .mb-4;

    &-item {
      @extend .px-1;

      &-title {
        @extend .text-small;
        text-transform: uppercase;
      }

      &-date {
        @extend .pl-3;
        @extend .text-small;
      }
    }
  }

  &-title {
    @extend .mb-2;
  }

  &-link {
    @extend .link;
    @extend .text-small;
  }

  @include media(">sm") {
    &-sign {
      position: absolute;
      top: 50%;
      right: 0;
      z-index: 0;
      display: block;
      width: 50%;
      transform: translateY(-50%);

      &-img {
        display: block;
        width: 100%;
      }
    }
  }


  @include media(">lg") {
    &-sign {
      width: rem(560px);
    }
  }
}
