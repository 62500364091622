//
// ------------------------------------------ Base
//
.form-radio {
  $self: &;

  &-text {
    @extend .text-small;
    padding-left: calc(#{rem(20px)} + #{spacing(2)});
    cursor: pointer;
    transition: $transition-base;

    &::before {
      position: absolute;
      top: rem(1px);
      left: 0;
      z-index: 0;
      width: rem(20px);
      height: rem(20px);
      content: "";
      background: $white;
      border: 1px solid $input-border;
      border-radius: 50%;
      transition: $transition-base;
    }

    &::after {
      position: absolute;
      top: rem(7px);
      left: rem(6px);
      z-index: 0;
      width: rem(8px);
      height: rem(8px);
      content: "";
      background: $input-selected-color;
      border-radius: 50%;
      opacity: 0;
      transition: $transition-base;
    }
  }

  &-input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;

    &:checked + #{$self}-text {
      &::after {
        opacity: 1;
      }
    }

    &.is-invalid {
      + #{$self}-text {
        &::before {
          border-color: $input-invalid-border;
        }
      }
    }

    &:focus {
      + #{$self}-text {
        &::before {
          @include focusKeyboard();
          box-shadow: $focus-shadow;
        }
      }
    }

    &:disabled {
      + #{$self}-text {
        color: $label-disabled-color;
        pointer-events: none;
        cursor: context-menu;

        &::before {
          border-color: $input-disabled-border;
        }

        &::after {
          background: $input-disabled-color;
        }
      }
    }
  }

  &-label {
    position: relative;
    display: inline-block;
    min-width: rem(20px);
    min-height: rem(20px);

    &:hover #{$self}-input:not(:disabled):not(:focus) {
      + #{$self}-text {
        color: $input-hover-border;

        &::before {
          border-color: $input-hover-border;
        }
      }
    }
  }
}


//
// ------------------------------------------ Group
//
.form-radio-group {
  $self: &;

  &-label {
    display: block;
    @extend .mb-4;
    @extend .text-smallest;
  }

  &-horizontal {
    .form-radio {
      display: inline-block;
      @extend .mr-4;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &-vertical {
    .form-radio {
      @extend .mb-4;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
