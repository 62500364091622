%header-esprawozdania {
  h4 {
    margin-bottom: rem(30px);
  }
}

.article-with-image {
  @extend %header-esprawozdania;

  .icon {
    display: block;
    margin-bottom: rem(15px);
    font-size: rem(35px);
    color: $primary;
  }

  .listbox {
    margin-top: rem(30px);
  }

  .listbox-item {
    padding-right: rem(30px);
    line-height: rem(25px);
  }

  .image {
    max-width: 100%;
    margin-bottom: rem(5px);
  }

  h4 {
    margin-bottom: rem(30px);
  }
}

.for-who {
  @extend %header-esprawozdania;
}

.custom-bullet {
  li {
    position: relative;
    padding: rem(5px 0 5px 15px);

    &::before {
      position: absolute;
      top: rem(14px);
      left: 0;
      width: $listbox-dot-size;
      height: $listbox-dot-size;
      content: "";
      background: $listbox-dot-color;
      border-radius: 50%;
    }
  }
}

.features {
  @extend .custom-bullet;
}

.header-flex {
  margin-bottom: rem(30px);

  img {
    max-width: 100%;
  }

  @include media-breakpoint-up(sm) {
    display: flex;

    div {
      margin-left: rem(30px);
    }
  }
}

.modal-info-msg {
  @extend .text-large;
  @extend .text-success;
}

ul {
  list-style: none;
}

footer {
  padding: rem(30px 0);
  color: $softGray;

  @include media-breakpoint-up(sm) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  ul {
    display: flex;
  }

  li {
    padding: rem(20px 0);
    margin-right: rem(20px);

    @include media-breakpoint-up(sm) {
      padding: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.scroll-down {
  position: absolute;
  right: 0;
  bottom: rem(-20px);
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  width: auto;
  margin: 0 auto;
  text-align: center;
  animation: bounce 2.5s infinite;

  &:hover {
    cursor: pointer;
  }
}

//CSS3 Bounce Animation
@keyframes bounce {

  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-10px);
  }
}

.text-nowrap {
  white-space: nowrap;
}

.navbar-login {
  font-size: rem(14px);
  text-align: right;

  @include media-breakpoint-up(sm) {
    font-size: rem(18px);
  }
}
