%color {
  position: relative;

  &::before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: -100%;
    z-index: -1;
    display: block;
    width: 200vw;
    content: "";
    background: transparent;
  }
}

.box {
  &--light-grey {
    @extend %color;

    &::before {
      background: $box-softgray-color;
    }
  }

  &--grey {
    @extend %color;

    &::before {
      background: $box-gray-color;
    }
  }
}
