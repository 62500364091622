.fade {
  transition: opacity $transition-time-function;

  &:not(.show) {
    opacity: 0;
  }
}

.collapse {
  display: none;

  &[aria-hidden="false"] {
    display: block;
  }
}

.collapsing {
  display: block;
  height: 0;
  overflow: hidden;
  transition: height $transition-time-function;
}
