.video-content {
  position: relative;

  .video-player {
    z-index: 0;
    width: 100%;
    cursor: pointer;
  }

  .video-cover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
    transition: opacity .2s linear;
    will-change: opacity;

    &:hover {
      @include media-breakpoint-up(sm) {
        .video-button {
          color: $video-color-hover;
        }

        .icon svg {
          fill: $video-color-hover;
        }
      }
    }

    &::before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      content: "";
      background: map-get($video-background, "xs");

      @include media-breakpoint-up(sm) {
        background: map-get($video-background, "xl");
      }
    }

    &.show {
      opacity: 1;
    }

    img {
      width: 100%;
    }

    .icon {
      width: map-get($video-size, "xs");
      height: map-get($video-size, "xs");
      margin: 0;
      margin-left: rem(20px);

      @include media-breakpoint-up(sm) {
        width: map-get($video-size, "xl");
        height: map-get($video-size, "xl");
      }

      svg {
        display: block;
        transition: fill .3s ease-in-out;
        fill: $video-color;
      }
    }
  }

  .video-button {
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    align-items: center;
    padding: 0;
    font-size: rem(18px);
    color: $video-color;
    transition: color .3s ease-in-out;
    transform: translate(-50%, -50%);


    @include media-breakpoint-up(sm) {
      top: auto;
      right: $video-pos-right;
      bottom: $video-pos-bottom;
      left: auto;
      transform: none;
    }
  }

  .video-caption {
    display: none;

    @include media-breakpoint-up(sm) {
      display: block;
    }
  }
}
