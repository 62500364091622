// Tables
//
.table {
  $self: &;
  border-top: 1px solid  color($softGrays, "40");

  &-header {
    display: none;
  }

  &-row {
    @extend .py-3;
    border-bottom: 1px solid color($softGrays, "40");
    transition: box-shadow $transition-time-function;
  }

  &-col {
    @extend .py-3;
    word-break: break-word;
    word-wrap: break-word;

    &::before {
      @extend .pb-2;
      display: block;
      color: color($grays, "60");
      content: attr(data-title);
    }

    &-no-title-xs {
      &::before {
        display: none;
      }
    }

    &-details {
      text-align: center;
    }
  }

  @include media(">lg") {
    border-top: 0;

    &-header {
      display: block;

      .table-col {
        padding-top: 0;
      }
    }

    &-col-header {
      display: flex;
      align-items: center;
      color: color($grays, "60");

      &.active {
        color: color($grays, "100");
      }
    }

    &-row {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      padding: 0;

      &-hoverable {
        &:hover {
          box-shadow: $tile-hover-shadow;
        }
      }
    }

    &-col {
      flex-shrink: 0;
      flex-grow: 0;
      padding-top: spacing(2);
      padding-right: spacing(3);
      padding-bottom: spacing(2);
      padding-left: spacing(3);

      &::before {
        display: none;
      }

      &-details {
        flex-basis: rem(40px);
        max-width: rem(40px);
        text-align: right;

        &-link {
          &-text {
            @include sr-only;
          }
        }
      }
    }
  }

  @include media(">xl") {
    &-col {
      padding-top: spacing(2);
      padding-right: spacing(3);
      padding-bottom: spacing(2);
      padding-left: spacing(3);
    }
  }
}
