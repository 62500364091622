// stylelint-disable rule-empty-line-before

//
// ------------------------------------------ Aligment
//

.text-justify  { text-align: justify; }
.text-wrap     { white-space: normal; }
.text-nowrap   { white-space: nowrap; }
.text-truncate { @include text-truncate; }


//
// ------------------------------------------ Responsive alignment
//
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .text#{$infix}-left   { text-align: left; }
    .text#{$infix}-right  { text-align: right; }
    .text#{$infix}-center { text-align: center; }
  }
}


//
// ------------------------------------------ Transformation
//
.text-lowercase  { text-transform: lowercase; }
.text-uppercase  { text-transform: uppercase; }
.text-capitalize { text-transform: capitalize; }


//
// ------------------------------------------ Weight and italics
//
.font-normal  { font-weight: $font-weight-normal; }
.font-bold    { font-weight: $font-weight-bold; }


//
// ------------------------------------------ Colors
//
.text-white { color: $white; }

@each $color, $value in $theme-colors {
  @include text-emphasis-variant(".text-#{$color}", $value);
}

.text-body { color: $body-color; }
.text-muted { color: $text-muted; }
.text-muted-light { color: $text-muted-light; }


//
// ------------------------------------------ Decroartion
//
.text-decoration-none { text-decoration: none; }


//
// ------------------------------------------ Reset
//
.text-reset { color: inherit; }
