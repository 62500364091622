.article-with-icon {
  @include media-breakpoint-up(sm) {
    display: flex;
    justify-items: flex-start;
    align-items: flex-start;
  }

  .btn {
    @extend .text-small;
  }
}

.article-icon {
  display: none;
  padding-top: rem(48px);
  padding-right: rem(16px);

  @include media-breakpoint-up(sm) {
    display: block;
  }

  i {
    font-size: rem(30px);
    color: color($oranges, "80");

    &::before {
      padding: rem(15px);
      border: 1px solid color($oranges, "80");
      border-radius: 50%;
    }
  }
}
