//
// ------------------------------------------ Base
//
.article-introduction {
  position: relative;
  @extend .py-6;

  &::before {
    @extend .mx-n4;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    display: block;
    width: 100vw;
    content: "";
    background: color($softGrays, "20");
  }

  &-summary {
    @extend .mt-6;
    border-left: 4px solid color($teals, "80");

    h3{
      margin-top: 0;
    }

    ul {
      margin-bottom: 0;
    }
  }

  @include media(">lg") {
    &::before {
      margin-left: spacing(6) * (-1);
    }
  }

  @include media(">xl") {
    &::before {
      margin-left: spacing(6) * (-1);
    }
  }
}
