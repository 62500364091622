.tile {
  @extend .p-4;
  background: $white;
  border: 1px solid color($softGrays, "100");
  border-bottom-width: 4px;

  &[type="button"] {
    @include focus();
    cursor: pointer;
    transition: box-shadow $transition-time-function;
    appearance: none;
    -webkit-appearance: none; /* stylelint-disable-line property-no-vendor-prefix, order/properties-order */
    -moz-appearance: none; /* stylelint-disable-line property-no-vendor-prefix */

    &:hover {
      box-shadow: $tile-hover-shadow;
    }
  }
}
