.banner {
  position: relative;
  width: 100%;
  padding: rem(30px 0);
  overflow: hidden;
  text-align: center;
  background: $white;

  img {
    width: 100%;
    max-width: 300px;
    margin-bottom: rem(20px);

    @include media-breakpoint-up(sm) {
      position: absolute;
      top: 0;
      right: 0;
      margin-bottom: 0;
    }

    @include media-breakpoint-up(sm) {
      display: none;
    }

    @include media-breakpoint-up(lg) {
      display: block;
    }

    /* stylelint-disable selector-no-qualifying-type */
    &.left-side {
      top: 50%;
      left: 30px;
      display: none;
      width: 130px;
      transform: translateY(-50%);

      @include media-breakpoint-up(md) {
        display: block;
      }
    }

    /* stylelint-enable selector-no-qualifying-type */
  }

  .btn {
    @extend .text-small;

    margin-top: rem(30px);
  }
}

.banner-column {
  background: color($softGrays, "40");

  @include media-breakpoint-up(sm) {
    display: flex;
    align-items: center;
  }

  .h3 {
    margin: 0;
    margin-left: rem(30px);

    i {
      display: none;
      margin-left: rem(5px);
      font-size: rem(22px);
      line-height: 1;

      @include media-breakpoint-up(sm) {
        display: inline;
      }
    }
  }

  img {
    width: 100%;
  }

  div {
    flex: 1;
    padding: rem(10px 0);

    &:first-child {
      margin-left: 0;
    }

    &:nth-child(2) {
      flex: 2;
    }

    @include media-breakpoint-up(sm) {
      padding: 0;
      margin-left: rem(20px);
    }

    /* stylelint-disable selector-no-qualifying-type */
    &.text-right {
      margin-right: rem(30px);
    }

    /* stylelint-enable selector-no-qualifying-type */
  }

  .link {
    &:hover {
      i {
        &::before {
          border-color: theme-color("danger");
        }
      }
    }

    i {
      margin-right: rem(10px);

      &::before {
        padding: rem(7px);
        border: 1px solid $primary;
        border-radius: 50%;
      }
    }
  }

  &--purple {
    @extend .banner-column;

    color: $white;
    background: color($purples, "100");
  }
}
