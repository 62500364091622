//
// ------------------------------------------ Variables
//
$input-disabled-color:        gray("40");
$input-selected-color:        theme-color("primary");
$input-placeolder-color:      color($grays, "60");

$input-border:                color($grays, "60");
$input-hover-border:          $link-hover-color;
$input-focus-border:          color($grays, "100");
$input-valid-border:          color($greens, "100");
$input-invalid-border:        color($reds, "100");
$input-disabled-border:       color($softGrays, "100");

$input-disabled-background:   color($softGrays, "20");

$label-focus-color:           theme-color("primary");
$label-disabled-color:        gray("40");
$label-valid-color:           color($greens, "100");
$label-invalid-color:         color($reds, "100");


//
// ------------------------------------------ Form label
//
.form-label {
  @extend .pb-2;
  @extend .text-label;
  display: block;
}


//
// ------------------------------------------ Form group
//
.form-group {
  @extend .pb-3;
}

.form-group-action {
  @include media(">=lg") {
    text-align: right;
  }
}

//
// ------------------------------------------ Form small text
//
.form-text {
  @extend .pt-2;
  @extend .text-smallest;
  display: flex;

  &-icon {
    display: block;
    @extend .pr-1;
  }

  &-body {
    display: block;
  }
}


//
// ------------------------------------------ Form group password
//
.form-group-password {
  position: relative;

  .form-field {
    padding-right: rem(80px);
  }

  &-link {
    position: absolute;
    top: 50%;
    right: rem(20px);
    transform: translateY(-50%);
  }
}


//
// ------------------------------------------ Form feedback
//
.form-feedback {
  @extend .pt-2;
  @extend .text-smallest;
  @extend .icon;
  @extend .icon-exclamation-mark;
  @include spacingCalc($element: padding-left, $spaceValue: rem(12px), $space: 1);
  position: relative;
  display: block;

  &::before {
    @extend .mt-2;
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    padding-top: rem(2px);
  }

  &.is-invalid {
    @extend .text-danger;
  }

  &.is-valid {
    @extend .text-success;
  }
}
