// stylelint-disable declaration-no-important, rule-empty-line-before

// Margin and Padding

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    // name of breakpoint
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    $breakpoint-spacers: map-get($spacers, $breakpoint);

    @each $prop, $abbrev in (margin: m, padding: p) {
      @each $size, $length in $breakpoint-spacers {
        .#{$abbrev}-#{$size} { #{$prop}: $length; }
        .#{$abbrev}t-#{$size},
        .#{$abbrev}y-#{$size} {
          #{$prop}-top: $length;
        }
        .#{$abbrev}r-#{$size},
        .#{$abbrev}x-#{$size} {
          #{$prop}-right: $length;
        }
        .#{$abbrev}b-#{$size},
        .#{$abbrev}y-#{$size} {
          #{$prop}-bottom: $length;
        }
        .#{$abbrev}l-#{$size},
        .#{$abbrev}x-#{$size} {
          #{$prop}-left: $length;
        }
      }
    }

    // Negative margins (e.g., where `.mb-n1` is negative version of `.mb-1`)
    @each $size, $length in $breakpoint-spacers {
      @if $size != 0 {
        .m-n#{$size} { margin: -$length; }
        .mt-n#{$size},
        .my-n#{$size} {
          margin-top: -$length;
        }
        .mr-n#{$size},
        .mx-n#{$size} {
          margin-right: -$length;
        }
        .mb-n#{$size},
        .my-n#{$size} {
          margin-bottom: -$length;
        }
        .ml-n#{$size},
        .mx-n#{$size} {
          margin-left: -$length;
        }
      }
    }
  }
}
