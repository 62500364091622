.tile-project {
  height: 100%;

  &-list .col-sm-6 {
    @extend .mb-4;
  }

  &-name.h4 {
    @extend .mb-3;
  }

  &-block {
    @extend .mb-4;
  }

  &-last-modified {
    &-label {
      @extend .text-small;
      @extend .mb-2;
      display: block;
      color: $text-muted-light;
    }
  }
}
