//
// ------------------------------------------ Base
//
.accordion {
  padding: 0;
  margin: 0;
  list-style: none;

  &-item {
    &-title {
      margin: 0;

      &-btn {
        position: relative;
        width: 100%;
        @extend .py-3;
        @extend .px-0;
        font-family: inherit;
        font-size: inherit;
        color: $body-color;
        text-align: left;
        background: none;
        border: 0;
        border-bottom: 1px solid color($softGrays, "40");
        @include focus();

        .icon-chevron-small-down {
          position: absolute;
          top: 50%;
          right: 0;
          color: $link-color;
          transition: transform $transition-time-function, color $transition-time-function;
          transform: translate(0, -50%);

          &::before {
            display: block;
          }
        }

        &[aria-expanded="true"] {
          .icon-chevron-small-down {
            transform: translate(0, -50%) rotate(180deg);
          }
        }

        &:not(.disabled):not(:disabled) {
          cursor: pointer;

          &:hover,
          &:active {
            .icon-chevron-small-down {
              color: $link-hover-color;
            }
          }

          &:focus {
            box-shadow: $focus-shadow;
          }
        }

        &.disabled,
        &:disabled {
          color: $body-color;
          pointer-events: none;

          .icon-chevron-small-down {
            color: $link-disabled-color;
          }
        }
      }
    }

    &-body {
      @extend .py-4;
    }
  }
}
