//
// ------------------------------------------ Variable
//
$nav-tab-border-color:        color($softGrays, "60");
$nav-tab-border-active-color: gray("40");
$nav-tab-active-color:        $body-color;


//
// ------------------------------------------ Tabs
//
.nav-tabs {
  border-bottom: 1px solid $nav-tab-border-active-color;

  > li {
    padding-right: 0;
    margin-bottom: -1px;

    > a {
      position: relative;
      @extend .py-2;
      @extend .px-4;
      margin-left: -1px;
      line-height: rem(30px);
      border: 1px solid $nav-tab-border-color;
      border-bottom-color: $nav-tab-border-active-color;

      &.active { // stylelint-disable-line selector-no-qualifying-type
        z-index: 2;
        color: $nav-tab-active-color;
        border-color: $nav-tab-border-active-color;
        border-bottom-color: #fff;
      }

      .badge {
        position: relative;
        top: rem(-2px);
      }
    }
  }
}
