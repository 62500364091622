/* stylelint-disable */
.icon-d-block {
  &::before {
    display: block;
  }
}

@font-face {
  font-family: "amethyst";
  src:url("./../fonts/amethyst/amethyst.eot");
  src:url("./../fonts/amethyst/amethyst.eot?#iefix") format("embedded-opentype"),
    url("./../fonts/amethyst/amethyst.woff") format("woff"),
    url("./../fonts/amethyst/amethyst.ttf") format("truetype"),
    url("./../fonts/amethyst/amethyst.svg#amethyst") format("svg");
  font-weight: normal;
  font-style: normal;
}

.icon {
  vertical-align: middle;
}

.icon:before {
  font-family: "amethyst" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-draw-down:before {
  content: "\e000";
}
.icon-arrow-draw-left:before {
  content: "\e001";
}
.icon-arrow-draw-right:before {
  content: "\e002";
}
.icon-arrow-draw-up:before {
  content: "\e003";
}
.icon-arrow-down:before {
  content: "\e004";
}
.icon-arrow-left:before {
  content: "\e005";
}
.icon-arrow-right:before {
  content: "\e006";
}
.icon-arrow-up:before {
  content: "\e007";
}
.icon-chevron-down:before {
  content: "\e008";
}
.icon-chevron-left:before {
  content: "\e009";
}
.icon-chevron-right:before {
  content: "\e00a";
}
.icon-chevron-up:before {
  content: "\e00b";
}
.icon-chevron-small-up:before {
  content: "\e00c";
}
.icon-chevron-small-right:before {
  content: "\e00d";
}
.icon-chevron-small-left:before {
  content: "\e00e";
}
.icon-chevron-small-down:before {
  content: "\e00f";
}
.icon-chevron-smal-right-double:before {
  content: "\e010";
}
.icon-chevron-smal-left-double:before {
  content: "\e011";
}
.icon-chevron-double-right:before {
  content: "\e012";
}
.icon-chevron-double-left:before {
  content: "\e013";
}
.icon-arrow-rewind:before {
  content: "\e014";
}
.icon-arrow-play:before {
  content: "\e015";
}
.icon-arrow-fast-forward:before {
  content: "\e016";
}
.icon-accounting:before {
  content: "\e017";
}
.icon-accuracy:before {
  content: "\e018";
}
.icon-airplane:before {
  content: "\e019";
}
.icon-apple:before {
  content: "\e01a";
}
.icon-approved:before {
  content: "\e01b";
}
.icon-arrow-increase:before {
  content: "\e01c";
}
.icon-artificial-intelligence:before {
  content: "\e01d";
}
.icon-atom-nucleus:before {
  content: "\e01e";
}
.icon-audio:before {
  content: "\e01f";
}
.icon-augmented-reality:before {
  content: "\e020";
}
.icon-award:before {
  content: "\e021";
}
.icon-award-ribbon:before {
  content: "\e022";
}
.icon-bag:before {
  content: "\e023";
}
.icon-banking:before {
  content: "\e024";
}
.icon-bar-graph:before {
  content: "\e025";
}
.icon-baseball:before {
  content: "\e026";
}
.icon-battery:before {
  content: "\e027";
}
.icon-bear:before {
  content: "\e028";
}
.icon-bitcoin:before {
  content: "\e029";
}
.icon-block-chain:before {
  content: "\e02a";
}
.icon-bluetooth:before {
  content: "\e02b";
}
.icon-bomb:before {
  content: "\e02c";
}
.icon-book:before {
  content: "\e02d";
}
.icon-box:before {
  content: "\e02e";
}
.icon-box-open:before {
  content: "\e02f";
}
.icon-brand-mobius:before {
  content: "\e030";
}
.icon-brexit:before {
  content: "\e031";
}
.icon-briefcase:before {
  content: "\e032";
}
.icon-building-apartment:before {
  content: "\e033";
}
.icon-building-commercial:before {
  content: "\e034";
}
.icon-building-dome:before {
  content: "\e035";
}
.icon-building-factory:before {
  content: "\e036";
}
.icon-building-house:before {
  content: "\e037";
}
.icon-bull:before {
  content: "\e038";
}
.icon-bullet-train:before {
  content: "\e039";
}
.icon-bus:before {
  content: "\e03a";
}
.icon-calendar:before {
  content: "\e03b";
}
.icon-camera:before {
  content: "\e03c";
}
.icon-car:before {
  content: "\e03d";
}
.icon-chain-link:before {
  content: "\e03e";
}
.icon-chair:before {
  content: "\e03f";
}
.icon-champagne-glass:before {
  content: "\e040";
}
.icon-chart-down:before {
  content: "\e041";
}
.icon-chart-up:before {
  content: "\e042";
}
.icon-check-box:before {
  content: "\e043";
}
.icon-check-list:before {
  content: "\e044";
}
.icon-check-mark:before {
  content: "\e045";
}
.icon-checklist:before {
  content: "\e046";
}
.icon-chess-piece:before {
  content: "\e047";
}
.icon-child:before {
  content: "\e048";
}
.icon-circle-overlap:before {
  content: "\e049";
}
.icon-clipboard:before {
  content: "\e04a";
}
.icon-clipboard-check:before {
  content: "\e04b";
}
.icon-clock:before {
  content: "\e04c";
}
.icon-cloud:before {
  content: "\e04d";
}
.icon-coffee-tea-cup:before {
  content: "\e04e";
}
.icon-cloud-accounting:before {
  content: "\e04f";
}
.icon-coin-stack:before {
  content: "\e050";
}
.icon-compass:before {
  content: "\e051";
}
.icon-computer-laptop:before {
  content: "\e052";
}
.icon-computer-programming:before {
  content: "\e053";
}
.icon-computer-servers:before {
  content: "\e054";
}
.icon-conversation:before {
  content: "\e055";
}
.icon-conversation-bubble:before {
  content: "\e056";
}
.icon-conversation-group:before {
  content: "\e057";
}
.icon-crane:before {
  content: "\e058";
}
.icon-credit-card:before {
  content: "\e059";
}
.icon-crop-wheat:before {
  content: "\e05a";
}
.icon-cross:before {
  content: "\e05b";
}
.icon-cube-3d:before {
  content: "\e05c";
}
.icon-cube-stack:before {
  content: "\e05d";
}
.icon-currency-botswana-bwp:before {
  content: "\e05e";
}
.icon-currency-dollar:before {
  content: "\e05f";
}
.icon-currency-euro:before {
  content: "\e060";
}
.icon-currency-pound:before {
  content: "\e061";
}
.icon-currency-yen:before {
  content: "\e062";
}
.icon-cyber-security:before {
  content: "\e063";
}
.icon-danger:before {
  content: "\e064";
}
.icon-dash:before {
  content: "\e065";
}
.icon-detour:before {
  content: "\e066";
}
.icon-diamond:before {
  content: "\e067";
}
.icon-diploma:before {
  content: "\e068";
}
.icon-do-not-enter:before {
  content: "\e069";
}
.icon-doctor:before {
  content: "\e06a";
}
.icon-document:before {
  content: "\e06b";
}
.icon-document-check:before {
  content: "\e06c";
}
.icon-documents:before {
  content: "\e06d";
}
.icon-download-1:before {
  content: "\e06e";
}
.icon-download-2:before {
  content: "\e06f";
}
.icon-drowning:before {
  content: "\e070";
}
.icon-economy-dollar:before {
  content: "\e071";
}
.icon-economy-euro:before {
  content: "\e072";
}
.icon-economy-pound:before {
  content: "\e073";
}
.icon-economy-won:before {
  content: "\e074";
}
.icon-economy-yen:before {
  content: "\e075";
}
.icon-electric-outlet:before {
  content: "\e076";
}
.icon-envelope:before {
  content: "\e077";
}
.icon-energy:before {
  content: "\e078";
}
.icon-exclamation-mark:before {
  content: "\e079";
}
.icon-eye:before {
  content: "\e07a";
}
.icon-facebook:before {
  content: "\e07b";
}
.icon-female-sign:before {
  content: "\e07c";
}
.icon-fireworks:before {
  content: "\e07d";
}
.icon-flag:before {
  content: "\e07e";
}
.icon-folder:before {
  content: "\e07f";
}
.icon-food-and-beverage:before {
  content: "\e080";
}
.icon-fork-knife-bed:before {
  content: "\e081";
}
.icon-gas-petrol:before {
  content: "\e082";
}
.icon-fork-knife-plate:before {
  content: "\e083";
}
.icon-gear:before {
  content: "\e084";
}
.icon-gears:before {
  content: "\e085";
}
.icon-glasses:before {
  content: "\e086";
}
.icon-globe:before {
  content: "\e087";
}
.icon-globe-map:before {
  content: "\e088";
}
.icon-googleplus:before {
  content: "\e089";
}
.icon-grad-cap:before {
  content: "\e08a";
}
.icon-graph-decreasing:before {
  content: "\e08b";
}
.icon-graph-increasing:before {
  content: "\e08c";
}
.icon-gym:before {
  content: "\e08d";
}
.icon-hammer:before {
  content: "\e08e";
}
.icon-hand-coins:before {
  content: "\e08f";
}
.icon-hand-heart:before {
  content: "\e090";
}
.icon-hand-helix:before {
  content: "\e091";
}
.icon-hand-percent:before {
  content: "\e092";
}
.icon-hand-pointing-down:before {
  content: "\e093";
}
.icon-hand-pointing-left:before {
  content: "\e094";
}
.icon-hand-pointing-right:before {
  content: "\e095";
}
.icon-hand-pointing-up:before {
  content: "\e096";
}
.icon-hand-thumbs-down:before {
  content: "\e097";
}
.icon-hand-thumbs-up:before {
  content: "\e098";
}
.icon-hands-box:before {
  content: "\e099";
}
.icon-handshake:before {
  content: "\e09a";
}
.icon-handshake-heart:before {
  content: "\e09b";
}
.icon-hard-hat:before {
  content: "\e09c";
}
.icon-head-lamp:before {
  content: "\e09d";
}
.icon-helix:before {
  content: "\e09e";
}
.icon-helmet:before {
  content: "\e09f";
}
.icon-hourglass:before {
  content: "\e0a0";
}
.icon-id-card:before {
  content: "\e0a1";
}
.icon-in-box:before {
  content: "\e0a2";
}
.icon-instagram:before {
  content: "\e0a3";
}
.icon-insurance:before {
  content: "\e0a4";
}
.icon-internet:before {
  content: "\e0a5";
}
.icon-intersection:before {
  content: "\e0a6";
}
.icon-jobs:before {
  content: "\e0a7";
}
.icon-key:before {
  content: "\e0a8";
}
.icon-keyboard:before {
  content: "\e0a9";
}
.icon-labyrinth:before {
  content: "\e0aa";
}
.icon-leaf:before {
  content: "\e0ab";
}
.icon-light-bulb:before {
  content: "\e0ac";
}
.icon-linkedin:before {
  content: "\e0ad";
}
.icon-location-marker:before {
  content: "\e0ae";
}
.icon-lock-locked:before {
  content: "\e0af";
}
.icon-lock-unlocked:before {
  content: "\e0b0";
}
.icon-magnifying-glass:before {
  content: "\e0b1";
}
.icon-male:before {
  content: "\e0b2";
}
.icon-male-sign:before {
  content: "\e0b3";
}
.icon-map-search:before {
  content: "\e0b4";
}
.icon-medical-cross:before {
  content: "\e0b5";
}
.icon-medical-hospital:before {
  content: "\e0b6";
}
.icon-megaphone:before {
  content: "\e0b7";
}
.icon-microphone:before {
  content: "\e0b8";
}
.icon-military-badge:before {
  content: "\e0b9";
}
.icon-milk-carton:before {
  content: "\e0ba";
}
.icon-music:before {
  content: "\e0bb";
}
.icon-newspaper:before {
  content: "\e0bc";
}
.icon-notebook:before {
  content: "\e0bd";
}
.icon-network:before {
  content: "\e0be";
}
.icon-notes:before {
  content: "\e0bf";
}
.icon-options:before {
  content: "\e0c0";
}
.icon-paper-airplane:before {
  content: "\e0c1";
}
.icon-paper-clip:before {
  content: "\e0c2";
}
.icon-paper-money:before {
  content: "\e0c3";
}
.icon-pause:before {
  content: "\e0c4";
}
.icon-pause-button:before {
  content: "\e0c5";
}
.icon-passport-citizenship:before {
  content: "\e0c6";
}
.icon-pen:before {
  content: "\e0c7";
}
.icon-people-female:before {
  content: "\e0c8";
}
.icon-people-organization:before {
  content: "\e0c9";
}
.icon-penetration-testing:before {
  content: "\e0ca";
}
.icon-people-team:before {
  content: "\e0cb";
}
.icon-percent:before {
  content: "\e0cc";
}
.icon-person:before {
  content: "\e0cd";
}
.icon-phone-mobile:before {
  content: "\e0ce";
}
.icon-phone:before {
  content: "\e0cf";
}
.icon-pie-chart-1:before {
  content: "\e0d0";
}
.icon-pie-chart-2:before {
  content: "\e0d1";
}
.icon-pie-chart-percent-1:before {
  content: "\e0d2";
}
.icon-pie-chart-percent-2:before {
  content: "\e0d3";
}
.icon-podium:before {
  content: "\e0d4";
}
.icon-power-button:before {
  content: "\e0d5";
}
.icon-printer:before {
  content: "\e0d6";
}
.icon-push-pin:before {
  content: "\e0d7";
}
.icon-puzzle-piece:before {
  content: "\e0d8";
}
.icon-recycle:before {
  content: "\e0d9";
}
.icon-road:before {
  content: "\e0da";
}
.icon-rocket:before {
  content: "\e0db";
}
.icon-question-mark:before {
  content: "\e0dc";
}
.icon-rosette:before {
  content: "\e0dd";
}
.icon-round-bottom-flask:before {
  content: "\e0de";
}
.icon-runner:before {
  content: "\e0df";
}
.icon-running:before {
  content: "\e0e0";
}
.icon-scales:before {
  content: "\e0e1";
}
.icon-scissors:before {
  content: "\e0e2";
}
.icon-shield-check:before {
  content: "\e0e3";
}
.icon-shield-health:before {
  content: "\e0e4";
}
.icon-shield-home:before {
  content: "\e0e5";
}
.icon-ship:before {
  content: "\e0e6";
}
.icon-shopping-cart:before {
  content: "\e0e7";
}
.icon-skyline:before {
  content: "\e0e8";
}
.icon-slideshare:before {
  content: "\e0e9";
}
.icon-slingshot:before {
  content: "\e0ea";
}
.icon-soccer:before {
  content: "\e0eb";
}
.icon-square:before {
  content: "\e0ec";
}
.icon-star:before {
  content: "\e0ed";
}
.icon-stop:before {
  content: "\e0ee";
}
.icon-stopwatch:before {
  content: "\e0ef";
}
.icon-sun:before {
  content: "\e0f0";
}
.icon-super-hero:before {
  content: "\e0f1";
}
.icon-supply-chain:before {
  content: "\e0f2";
}
.icon-table:before {
  content: "\e0f3";
}
.icon-tablet:before {
  content: "\e0f4";
}
.icon-tag:before {
  content: "\e0f5";
}
.icon-talkbubble:before {
  content: "\e0f6";
}
.icon-target:before {
  content: "\e0f7";
}
.icon-taxi:before {
  content: "\e0f8";
}
.icon-technology:before {
  content: "\e0f9";
}
.icon-technology-info:before {
  content: "\e0fa";
}
.icon-thought-bubble:before {
  content: "\e0fb";
}
.icon-thought-gear:before {
  content: "\e0fc";
}
.icon-thought-lightning:before {
  content: "\e0fd";
}
.icon-ticket:before {
  content: "\e0fe";
}
.icon-trade:before {
  content: "\e0ff";
}
.icon-train:before {
  content: "\e100";
}
.icon-trash:before {
  content: "\e101";
}
.icon-tree:before {
  content: "\e102";
}
.icon-trophy:before {
  content: "\e103";
}
.icon-tv:before {
  content: "\e104";
}
.icon-truck:before {
  content: "\e105";
}
.icon-twitter:before {
  content: "\e106";
}
.icon-umbrella:before {
  content: "\e107";
}
.icon-upload-1:before {
  content: "\e108";
}
.icon-upload-2:before {
  content: "\e109";
}
.icon-vacation:before {
  content: "\e10a";
}
.icon-victory-sign:before {
  content: "\e10b";
}
.icon-warning:before {
  content: "\e10c";
}
.icon-watch:before {
  content: "\e10d";
}
.icon-wifi:before {
  content: "\e10e";
}
.icon-wearable:before {
  content: "\e10f";
}
.icon-weibo:before {
  content: "\e110";
}
.icon-wrench:before {
  content: "\e111";
}
.icon-youtube:before {
  content: "\e112";
}
.icon-www-internet:before {
  content: "\e113";
}
.icon-check:before {
  content: "\e114";
}
.icon-close:before {
  content: "\e115";
}

